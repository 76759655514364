var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "viewBox": "0 0 14 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', [_c('clipPath', [_c('path', {
    attrs: {
      "fill": "#fff",
      "d": "M0 0h14v14H0z"
    }
  })]), _vm._v(" "), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "m6.796 13.467-.995.445a1 1 0 0 1-1.22-.326l-.638-.883a.5.5 0 0 0-.354-.204l-1.083-.112a1 1 0 0 1-.893-.893l-.111-1.083a.5.5 0 0 0-.205-.354l-.883-.639a1 1 0 0 1-.327-1.22l.446-.994a.5.5 0 0 0 0-.408L.087 5.8a1 1 0 0 1 .327-1.22l.883-.638a.5.5 0 0 0 .205-.354l.111-1.083a1 1 0 0 1 .893-.893l1.083-.111a.5.5 0 0 0 .354-.205l.639-.883a1 1 0 0 1 1.22-.327l.994.446a.5.5 0 0 0 .408 0L8.2.087a1 1 0 0 1 1.219.327l.639.883a.5.5 0 0 0 .354.205l1.084.111a1 1 0 0 1 .892.893l.111 1.083a.5.5 0 0 0 .205.354l.883.639a1 1 0 0 1 .326 1.22l-.445.994a.5.5 0 0 0 0 .408l.445.995a1 1 0 0 1-.326 1.219l-.883.639a.5.5 0 0 0-.204.354l-.112 1.084a1 1 0 0 1-.893.892l-1.083.111a.5.5 0 0 0-.354.205l-.639.883a1 1 0 0 1-1.22.326l-.994-.445a.5.5 0 0 0-.408 0ZM4.723 6.478a.6.6 0 1 0-.849.849l2.424 2.424 3.95-3.948a.6.6 0 1 0-.849-.849l-3.1 3.1-1.576-1.576Z",
      "fill": "#5A8CF1"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }